import React, { useEffect, useState } from 'react'
import './style.less'
import { isSameDay, isAfter, toDate, isBefore } from 'date-fns'
import 'react-nice-dates/build/style.css'
import { Button, message, Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { startOfToday } from 'date-fns/esm'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { PeriodConfig } from './PeriodConfig'
import { dataModifiers, ILLChange, IPeriodCreateModal, IDateRange } from '../../lib/interfaces'
import {
    convertDateToDKTime,
    dataReceiver,
    formatText,
    getCalendarLayoutDates,
    isInsidePeriod,
    layoutDictKeyFormat,
    mapPeriodData,
} from '../../lib/dateFunctions'
import { httpDelete, httpGet, httpPost } from '../../services/http'
import { MenuModal } from '../CalendarDayMenu'
import RawCalendar from './RawCalendar'
import { editType } from '../../lib/enums'
import { useLocation } from '@reach/router'
import * as dayjs from 'dayjs'
import { Stack, Typography } from '@mui/material'
import { createBillingModal } from '../modals/purchaseModal'
import mainApi from '../../redux/services/mainApi'
import { useDispatch } from 'react-redux'

function SimpleCalendarPage({
    dateRanges,
    setDateRanges,
    isSelecting,
    setIsSelecting,
    setIsLoading,
    docDates,
    setDocDates,
    llPeriods,
    setSelectingEXPeriod,
    selectingEXPeriod,
    updateData,
    session,
    userNotes,
    setUserNotes,
    userLocationDates,
    setUserLocationDates,
    rosterDates,
    calendarMonth,
    setCalendarMonth,
    setIsBackgroundLoading,
}: dataModifiers) {
    const [menuModalVisible, setMenuModalVisible] = useState<boolean>(false)
    const [isNotified, setIsNotified] = useState<boolean>(false)
    const [menuModalText, setMenuModalText] = useState<string | undefined>(undefined)
    const [currentDate, setCurrentDate] = useState<Date | number>(startOfToday())
    const [createEXPeriod, setCreateEXPeriod] = useState<IPeriodCreateModal>({
        periodCreate: null,
        changeType: null,
        visible: false,
    })
    const [layoutDates, setLayoutDates] = useState<{ [id: number]: string }>({})
    const [didReactToDeepLink, setDidReactToDeepLink] = useState<boolean>(false)
    const location = useLocation()
    const { openDialog: openBilllingModal, DialogComponent: BillingModal } =
        createBillingModal(session)

    useEffect(() => {
        setLayoutDates(
            getCalendarLayoutDates(
                dateRanges,
                docDates,
                userNotes,
                rosterDates,
                llPeriods,
                userLocationDates
            )
        )
    }, [dateRanges, docDates, userNotes, rosterDates, llPeriods, userLocationDates])

    useEffect(() => {
        if (
            !isNotified &&
            session.user?.current_period_end &&
            isBefore(toDate(session.user?.current_period_end), startOfToday())
        ) {
            setIsNotified(true)
            openBilllingModal()
        }
    }, [session.user?.current_period_end, isNotified])

    useEffect(() => {
        if (!location.search) return
        const caledarDay = new URLSearchParams(location.search).get('calendar_day')
        if (!caledarDay || didReactToDeepLink) {
            return
        }
        const dateToShow = dayjs(caledarDay)
        if (!dateToShow || !setCalendarMonth) return
        setCalendarMonth(dateToShow.toDate())
        handleDayClick(dateToShow.toDate())
        setDidReactToDeepLink(true)
    }, [setCalendarMonth, location?.search])

    useEffect(() => {
        setMenuModalText(formatText(toDate(currentDate), 'dd. MMMM'))
    }, [currentDate])
    const dispatch = useDispatch()

    const startPeriod = (date: any) => {
        setIsLoading(true)
        if (isInsidePeriod(dateRanges, convertDateToDKTime(date))) {
            setIsLoading(false)
            Modal.error({
                title: 'You cannot have overlapping periods',
                content:
                    'Either edit or delete the existing period or start your period another time',
            })
            return
        }
        setDateRanges([...dateRanges, { start: date, end: null }])
        setIsSelecting(true)
        setIsLoading(false)
    }

    const cancelSelect = () => {
        if (isSelecting) {
            setDateRanges(dateRanges.slice(0, dateRanges.length - 1))
        }
        setSelectingEXPeriod({
            period: null,
            changeType: null,
        })
        setIsSelecting(false)
        setCreateEXPeriod({
            periodCreate: createEXPeriod.periodCreate,
            changeType: null,
            visible: false,
        })
    }

    const insertPeriod = async (
        dateRangeToInsert: IDateRange,
        showModalOnError: boolean = true
    ) => {
        try {
            await httpPost('/period', {
                start: formatText(toDate(dateRangeToInsert.start), layoutDictKeyFormat),
                end: formatText(toDate(dateRangeToInsert.end), layoutDictKeyFormat),
            })
            message.success('Added dk period!')
            setDateRanges(mapPeriodData((await httpGet<dataReceiver>('/periods')).data))
            await updateData('day-release graph ll-period')
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            if (error.status && error.status === 402) {
                openBilllingModal()
                return
            }
            if (dateRanges.length > 0 && !dateRanges[dateRanges.length - 1].end) {
                setDateRanges(dateRanges.slice(0, dateRanges.length - 1))
            }
            if (showModalOnError) {
                Modal.error({
                    title: 'Could not complete period',
                    content: error.data?.detail,
                })
            }
        }
    }

    const deletePeriod = async (dateRangeToDelete: IDateRange) => {
        setIsLoading(true)
        try {
            await httpDelete('/period', {
                start: formatText(toDate(dateRangeToDelete.start), layoutDictKeyFormat),
                end: formatText(toDate(dateRangeToDelete.end), layoutDictKeyFormat),
            })
            message.info('Deleted dk period!')
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            if (error.status && error.status === 402) {
                openBilllingModal()
                return
            }
            Modal.error({
                title: 'Could not remove dk period',
                content: error.data.detail,
            })
            return
        }
        setDateRanges(
            dateRanges.filter(
                (dateRange) =>
                    !(
                        dateRange.start === dateRangeToDelete.start &&
                        dateRange.end === dateRangeToDelete.end
                    )
            )
        )
        await updateData('day-release graph ll-period')
    }

    async function showDKConfirm(date: number | Date, changeType: editType) {
        let result: string | boolean | null = 'notDone'
        const selectHintStart =
            'The start of exemption periods have to be at the last day of a stay in DK'
        const selectHintEnd =
            'The end of exemption periods have to be at the first day of a stay in DK'
        Modal.confirm({
            title: changeType === editType.Start ? selectHintStart : selectHintEnd,
            icon: <ExclamationCircleOutlined />,
            content:
                'Can you confirm that you ' +
                (changeType === editType.Start
                    ? isAfter(toDate(date), startOfToday())
                        ? 'will be leaving'
                        : 'left'
                    : isAfter(toDate(date), startOfToday())
                    ? 'will be arriving in'
                    : 'arrived in') +
                ' Denmark on ' +
                formatText(toDate(date), layoutDictKeyFormat) +
                '? Please mark the day as a transfer day before using it as an opening or closing of an exemption period',
            okText: 'Ok',
            cancelText: 'Cancel',
            onOk() {
                return new Promise<void>((resolve, reject) => {
                    insertPeriod({ start: date, end: date }, false)
                        .then((r) => {
                            result = changeType === editType.Start
                            resolve()
                        })
                        .catch((error) => {
                            if (error.data && error.data.detail) {
                                message.error(error.toString())
                            }
                            console.log(error)
                            result = null
                            reject()
                        })
                }).catch(() => console.log('Oops errors!'))
            },
            onCancel() {
                result = null
            },
        })
        while (result === 'notDone') {
            await new Promise((resolve) => {
                setTimeout(resolve, 100)
            })
        }
        return result
    }

    const handleDayClick = async (date: number | Date | null) => {
        if (date === null) {
            return
        }
        setIsLoading(true)
        setCurrentDate(date)
        if (
            isSelecting === false &&
            selectingEXPeriod.changeType === null &&
            createEXPeriod.changeType === null
        ) {
            setMenuModalVisible(true)
        } else if (selectingEXPeriod.changeType) {
            var is_start
            var fromConfirmModal = false
            if (
                llPeriods?.find(
                    (p) =>
                        p.id !== selectingEXPeriod?.period?.id &&
                        dayjs(date).isBefore(dayjs(p.end)) &&
                        dayjs(date).isAfter(dayjs(p.start))
                )
            ) {
                Modal.error({
                    title: `Invalid date to ${selectingEXPeriod.changeType} exemption period`,
                    content:
                        'Denne dato er allerede tilhørende et andet udlandsophold (exemption period)',
                })
                setIsLoading(false)
                return
            }
            if (
                selectingEXPeriod.changeType === editType.Start &&
                dateRanges.some((dateRange: IDateRange) =>
                    isSameDay(dateRange.end, convertDateToDKTime(date))
                )
            ) {
                is_start = true
            } else if (
                selectingEXPeriod.changeType === editType.End &&
                dateRanges.some((dateRange: IDateRange) =>
                    isSameDay(dateRange.start, convertDateToDKTime(date))
                )
            ) {
                is_start = false
            } else if (
                dateRanges.some((dateRange: IDateRange) => {
                    return (
                        dateRange.end !== null &&
                        isAfter(convertDateToDKTime(date), toDate(dateRange.start)) &&
                        isAfter(toDate(dateRange.end), convertDateToDKTime(date))
                    )
                }) ||
                (selectingEXPeriod.changeType === editType.Start &&
                    dateRanges.some((dateRange: IDateRange) =>
                        isSameDay(dateRange.start, convertDateToDKTime(date))
                    )) ||
                (selectingEXPeriod.changeType === editType.End &&
                    dateRanges.some((dateRange: IDateRange) =>
                        isSameDay(dateRange.end, convertDateToDKTime(date))
                    ))
            ) {
                const selectHintStart =
                    'The start of exemption periods have to be at the last day of a stay in DK'
                const selectHintEnd =
                    'The end of exemption periods have to be at the first day of a stay in DK'
                Modal.error({
                    title: `Invalid date to ${selectingEXPeriod.changeType} exemption period`,
                    content:
                        selectingEXPeriod.changeType === editType.Start
                            ? selectHintStart
                            : selectHintEnd,
                })
                setIsLoading(false)
                return
            } else {
                is_start = await showDKConfirm(
                    convertDateToDKTime(date),
                    selectingEXPeriod.changeType
                )
                if (is_start === null) {
                    setIsLoading(false)
                    return
                }
                fromConfirmModal = true
            }
            setIsLoading(true)
            try {
                await httpPost<ILLChange>('/change-ll-period', {
                    update_date: formatText(toDate(date), layoutDictKeyFormat),
                    is_start: is_start,
                    ll_period: selectingEXPeriod.period?.id,
                })
                dispatch(mainApi.util.resetApiState())
                message.success('Updated exemption period!')
                setIsLoading(false)
            } catch (error) {
                if (error.status && error.status === 402) {
                    openBilllingModal()
                    setIsLoading(false)
                    return
                }
                if (fromConfirmModal) {
                    await deletePeriod({
                        start: convertDateToDKTime(date),
                        end: convertDateToDKTime(date),
                    })
                }
                setIsLoading(false)
                Modal.error({
                    title: `Invalid date to ${selectingEXPeriod.changeType} exemption period`,
                    content: error.data?.detail,
                })
                return
            }
            setSelectingEXPeriod({
                period: null,
                changeType: null,
            })
            // await updateData();
            await updateData('day-release graph ll-period')
        } else if (createEXPeriod.changeType) {
            if (
                createEXPeriod.changeType === editType.Start &&
                dateRanges.some((dateRange: IDateRange) =>
                    isSameDay(dateRange.end, convertDateToDKTime(date))
                )
            ) {
                setCreateEXPeriod({
                    visible: true,
                    periodCreate: {
                        start: convertDateToDKTime(date),
                        end: createEXPeriod.periodCreate?.end || null,
                    },
                    changeType: null,
                })
            } else if (
                createEXPeriod.changeType === editType.End &&
                dateRanges.some((dateRange: IDateRange) =>
                    isSameDay(dateRange.start, convertDateToDKTime(date))
                )
            ) {
                setCreateEXPeriod({
                    visible: true,
                    periodCreate: {
                        start: createEXPeriod.periodCreate?.start || null,
                        end: date,
                    },
                    changeType: null,
                })
            } else if (
                dateRanges.some((dateRange: IDateRange) => {
                    return (
                        dateRange.end !== null &&
                        isAfter(toDate(convertDateToDKTime(date)), toDate(dateRange.start)) &&
                        isAfter(toDate(dateRange.end), toDate(convertDateToDKTime(date)))
                    )
                }) ||
                (createEXPeriod.changeType === editType.Start &&
                    dateRanges.some((dateRange: IDateRange) =>
                        isSameDay(dateRange.start, convertDateToDKTime(date))
                    )) ||
                (createEXPeriod.changeType === editType.End &&
                    dateRanges.some((dateRange: IDateRange) =>
                        isSameDay(dateRange.end, convertDateToDKTime(date))
                    ))
            ) {
                const selectHintStart =
                    'The start of exemption periods have to be at the last day of a stay in DK'
                const selectHintEnd =
                    'The end of exemption periods have to be at the first day of a stay in DK'
                Modal.error({
                    title: `Invalid date to ${createEXPeriod.changeType} exemption period`,
                    content:
                        createEXPeriod.changeType === editType.Start
                            ? selectHintStart
                            : selectHintEnd,
                })
                setIsLoading(false)
                return
            } else {
                is_start = await showDKConfirm(convertDateToDKTime(date), createEXPeriod.changeType)
                if (is_start === null) {
                    setIsLoading(false)
                    return
                }
                if (is_start) {
                    setCreateEXPeriod({
                        visible: true,
                        periodCreate: {
                            start: convertDateToDKTime(date),
                            end: createEXPeriod.periodCreate?.end || null,
                        },
                        changeType: null,
                    })
                } else {
                    setCreateEXPeriod({
                        visible: true,
                        periodCreate: {
                            start: createEXPeriod.periodCreate?.start || null,
                            end: convertDateToDKTime(date),
                        },
                        changeType: null,
                    })
                }
            }
        } else {
            if (date) {
                setIsLoading(true)
                const dateRangeToInsert = {
                    start: dateRanges[dateRanges.length - 1].start,
                    end: convertDateToDKTime(date),
                }
                await insertPeriod(dateRangeToInsert)
            }
            setIsSelecting(false)
        }
        setIsLoading(false)
    }

    return (
        <>
            {BillingModal}
            <div className="calendar-container">
                <MenuModal
                    setIsBackgroundLoading={setIsBackgroundLoading}
                    visible={menuModalVisible}
                    setVisible={setMenuModalVisible}
                    title={menuModalText}
                    startPeriod={startPeriod}
                    dateClicked={currentDate}
                    setCurrentDate={setCurrentDate}
                    dateRanges={dateRanges}
                    deletePeriod={deletePeriod}
                    docDates={docDates}
                    setDocDates={setDocDates}
                    userNotes={userNotes}
                    setUserNotes={setUserNotes}
                    updateData={updateData}
                    rosterDates={rosterDates}
                    userLocationDates={userLocationDates}
                    setUserLocationDates={setUserLocationDates}
                    session={session}
                />
                <RawCalendar
                    onDayClick={handleDayClick}
                    layoutDates={layoutDates}
                    month={calendarMonth}
                    onMonthChange={setCalendarMonth}
                />
                {isSelecting || selectingEXPeriod.changeType || createEXPeriod.changeType ? (
                    <Stack direction="column" spacing={1} sx={{ textAlign: 'center' }}>
                        <Typography variant="h5">
                            Vælg en{' '}
                            {(selectingEXPeriod.changeType ?? createEXPeriod.changeType) ===
                            editType.End
                                ? 'indrejse'
                                : 'udrejse'}{' '}
                            dag af Danmark
                            <Typography>(Brug pilene over kalenderen til skifte måned)</Typography>
                        </Typography>
                        <Button
                            type="primary"
                            className="cancel-select-btn"
                            onClick={cancelSelect}
                            icon={<CloseCircleOutlined />}
                        >
                            Cancel
                        </Button>
                    </Stack>
                ) : (
                    <PeriodConfig
                        dateRanges={llPeriods}
                        setSelectingEXPeriod={setSelectingEXPeriod}
                        selectingEXPeriod={selectingEXPeriod}
                        createEXPeriod={createEXPeriod}
                        setCreateEXPeriod={setCreateEXPeriod}
                        updateEXPeriods={updateData}
                    />
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </>
    )
}

export default SimpleCalendarPage
