import * as React from 'react'
import {
    useDateSpecificationQuery,
    useUpsertDateSpecificationMutation,
} from '../../redux/services/dateSpecification'
import { Button, CircularProgress, Menu, MenuItem, MenuList } from '@mui/material'
import * as dayjs from 'dayjs'
import { formatText, layoutDictKeyFormat } from '../../lib/dateFunctions'

export const specificationMap = {
    off_day: 'Fridag',
    sick_day: 'Sygedag',
    work_day_unrelated: 'Arbejdsdag uden tilknytning',
    work_day_related: 'Arbejdsdag med tilknytning',
}

export default function ({ targetDate }: { targetDate: number | Date }) {
    const targetDateString = dayjs(targetDate).isValid()
        ? formatText(targetDate, layoutDictKeyFormat)
        : null
    const { data, isFetching } = useDateSpecificationQuery(targetDateString || '', {
        skip: targetDateString == null,
    })
    const [upsertDateSpecification, { isLoading }] = useUpsertDateSpecificationMutation()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const buttonRef = React.useRef<HTMLButtonElement>(null)
    const [addCategoryLoading, setAddCategoryLoading] = React.useState<boolean>(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleCategorySelection = async (category: string) => {
        setAddCategoryLoading(true)
        await upsertDateSpecification({ targetDate: targetDateString, body: { category } })
        setAddCategoryLoading(false)
    }

    return (
        <>
            <Button
                color="info"
                variant="contained"
                onClick={handleClick}
                ref={buttonRef}
                sx={{ textTransform: 'none' }}
                endIcon={
                    addCategoryLoading || isFetching || isLoading ? (
                        <CircularProgress color="inherit" />
                    ) : null
                }
            >
                Kategori:{' '}
                {data?.specification == null
                    ? 'Ikke specificeret'
                    : specificationMap[data.specification]}
            </Button>
            <Menu
                id="category-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
            >
                <MenuList
                    style={{
                        minWidth: buttonRef.current ? buttonRef.current.offsetWidth : undefined,
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose()
                            handleCategorySelection('off_day')
                        }}
                        value={'off_day'}
                    >
                        {specificationMap.off_day}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose()
                            handleCategorySelection('sick_day')
                        }}
                        value={'sick_day'}
                    >
                        {specificationMap.sick_day}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose()
                            handleCategorySelection('work_day_unrelated')
                        }}
                        value={'work_day_unrelated'}
                    >
                        {specificationMap.work_day_unrelated}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose()
                            handleCategorySelection('work_day_related')
                        }}
                        value={'work_day_related'}
                    >
                        {specificationMap.work_day_related}
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}
