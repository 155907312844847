import { IconButton, Popover } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import * as React from 'react'

export default function ({ helpContent }: any) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleHelpClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    return (
        <>
            <IconButton aria-describedby={id} onClick={handleHelpClick}>
                <HelpOutlineIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleHelpClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {helpContent}
            </Popover>
        </>
    )
}
