import { Button, message, Modal, Popconfirm, Row, Input } from 'antd'
import { EditOutlined, DownOutlined } from '@ant-design/icons'
import { toDate } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { httpDelete, httpPost, httpPut } from '../../services/http'
import { colorConverter, colorSchema } from '../../lib/periodColors'
import './style.less'
import { ILLChange, IPeriodConfig, IExemptionModal, IPeriodCache } from '../../lib/interfaces'
import { editType } from '../../lib/enums'
import { formatText, layoutDictKeyFormat } from '../../lib/dateFunctions'
import { createBillingModal } from '../modals/purchaseModal'
import { useSessionIfExists } from '../../hooks/useAuth'
import { Box, Typography } from '@mui/material'
import InfoBox from '../dialogs/InfoBox'
import dayjs from 'dayjs'
const { Search } = Input

function StartButton({ editPeriodBound }: any) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button onClick={() => editPeriodBound(editType.Start)}>Edit start date</Button>
            <InfoBox
                helpContent={
                    <Typography sx={{ p: 2 }}>
                        The exemption period can start on a date which;
                        <br />
                        you leave Denmark.
                    </Typography>
                }
            />
        </Box>
    )
}

function EndButton({ editPeriodBound }: any) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button onClick={() => editPeriodBound(editType.End)}>Edit end date</Button>
            <InfoBox
                helpContent={
                    <Typography sx={{ p: 2 }}>
                        The exemption period can end on a date which;
                        <br />
                        you arrive in Denmark at least 6 months after period start.
                    </Typography>
                }
            />
        </Box>
    )
}

function EarliestEndInfo({ startDate }: { startDate: Date | null }) {
    if (!startDate) {
        return <></>
    }

    const earliestEnd = dayjs(new Date(startDate)).add(6, 'months').subtract(1, 'day').toDate()

    return (
        <Typography>
            Earliest possible end with given start:{' '}
            <Typography component="span" fontWeight="bold">
                {formatText(toDate(earliestEnd), 'yyyy MMM d.')}
            </Typography>
        </Typography>
    )
}

export function PeriodConfig({
    dateRanges,
    selectingEXPeriod,
    setSelectingEXPeriod,
    setCreateEXPeriod,
    createEXPeriod,
    updateEXPeriods,
}: IPeriodConfig) {
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [periodToConfigure, setPeriodToConfigure] = useState<IPeriodCache | null>(null)

    const showModal = (idx: number) => {
        setPeriodToConfigure(dateRanges[idx])
        setModalVisible(true)
    }

    const startNewPeriod = () => {
        setPeriodToConfigure(null)
        setCreateEXPeriod({
            periodCreate: { start: null, end: null },
            changeType: null,
            visible: true,
        })
    }

    return (
        <div className="ll-period-container">
            <div className="ll-period-title">
                <h3 className="title-text">
                    <DownOutlined style={{ paddingRight: '10px' }} />
                    Your LL § 33A Exemption Periods
                    <DownOutlined style={{ paddingLeft: '10px' }} />
                </h3>
            </div>
            {dateRanges.map((p, idx) => {
                return (
                    <Button
                        className="ll-period-row"
                        type="primary"
                        style={{
                            backgroundColor: colorConverter[p.period_color],
                        }}
                        onClick={() => {
                            showModal(idx)
                        }}
                        key={idx}
                    >
                        <div className="exp-render">
                            <p>
                                {(p.start && formatText(toDate(p.start), 'yyyy/MM/dd')) ||
                                    'Open Start'}
                            </p>
                            <p>-</p>
                            <p>
                                {' '}
                                {(p.end && formatText(toDate(p.end), 'yyyy/MM/dd')) || 'Open End'}
                            </p>
                        </div>
                        <div>
                            <p style={{ textAlign: 'center' }}>{p.period_title}</p>
                        </div>
                        <div>
                            {(p.max_day_count > 42 && (
                                <p style={{ textAlign: 'center', color: 'red' }}>
                                    Max: {p.max_day_count}
                                </p>
                            )) || <p style={{ textAlign: 'center' }}>Max: {p.max_day_count}</p>}
                        </div>
                    </Button>
                )
            })}
            <Button onClick={startNewPeriod} className="ll-period-btn">
                + Add LL § 33A Exemption Period
            </Button>
            <ExemptionModal
                key="1"
                visible={modalVisible}
                setVisible={setModalVisible}
                period={periodToConfigure}
                setSelectingEXPeriod={setSelectingEXPeriod}
                selectingEXPeriod={selectingEXPeriod}
                setCreateEXPeriod={setCreateEXPeriod}
                createEXPeriod={createEXPeriod}
                updateEXPeriods={updateEXPeriods}
            />
            <CreateExemptionModal
                key="2"
                visible={false}
                setVisible={setModalVisible}
                period={periodToConfigure}
                setSelectingEXPeriod={setSelectingEXPeriod}
                selectingEXPeriod={selectingEXPeriod}
                setCreateEXPeriod={setCreateEXPeriod}
                createEXPeriod={createEXPeriod}
                updateEXPeriods={updateEXPeriods}
            />
        </div>
    )
}

function ExemptionModal({
    visible,
    setVisible,
    period,
    setSelectingEXPeriod,
    updateEXPeriods,
}: IExemptionModal) {
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [changingTitle, setChangingTitle] = useState<boolean>(false)
    const [periodTitle, setPeriodTitle] = useState<string | null>(null)
    const [periodColor, setPeriodColor] = useState<string | null>(null)
    const [changingTitleLoading, setChangingTitleLoading] = useState<boolean>(false)
    const editPeriodBound = (inputEditType: editType) => {
        setSelectingEXPeriod({
            period: period,
            changeType: inputEditType,
        })
        setVisible(false)
    }
    const { session } = useSessionIfExists()
    const { openDialog: openBilllingModal, DialogComponent: BillingModal } =
        createBillingModal(session)

    useEffect(() => {
        setPeriodColor(period?.period_color)
        setPeriodTitle(period?.period_title)
    }, [period])

    const deleteEXPeriod = async () => {
        setDeleteLoading(true)
        try {
            await httpDelete<ILLChange>('/ll-period', {
                ll_period: period?.id,
            })
            await updateEXPeriods('graph day-release ll-period')
            message.success('Deleted exemption period!')
        } catch (error) {
            if (error.status && error.status === 402) {
                openBilllingModal()
            }
            setChangingTitleLoading(false)
            message.error('something went wrong')
        }
        setDeleteLoading(false)
        setVisible(false)
    }

    const updateTitle = async (value: string) => {
        setChangingTitleLoading(true)
        try {
            await httpPut<ILLChange>('/change-ll-period-title', {
                ll_period: period?.id,
                title: value,
            })
            await updateEXPeriods('graph ll-period')
            setPeriodTitle(value)
            message.success('Changed title!')
        } catch (error) {
            if (error.status && error.status === 402) {
                openBilllingModal()
            }
            setChangingTitleLoading(false)
            message.error('something went wrong')
        }
        setChangingTitle(false)
        setChangingTitleLoading(false)
    }

    const closeModal = () => {
        setVisible(false)
        setChangingTitle(false)
        setChangingTitleLoading(false)
        setPeriodTitle(null)
    }

    const changeColor = async (key: any) => {
        // setChangingTitleLoading(true)
        try {
            await httpPut<ILLChange>('/change-ll-period-color', {
                ll_period: period?.id,
                color: key.target.value,
            })
            // const start = new Date();
            // console.log(start)
            await updateEXPeriods('graph ll-period')
            // const ptime = (new Date()).valueOf() - start.valueOf()
            // console.log('total fun', ptime)
            setPeriodColor(key.target.value)
            message.success('Changed color!')
        } catch (error) {
            if (error.status && error.status === 402) {
                openBilllingModal()
            }
            // setChangingTitleLoading(false)
            message.error('something went wrong')
        }
        // setChangingTitleLoading(false);
    }

    return (
        <Modal visible={visible} onCancel={closeModal} onOk={closeModal} destroyOnClose footer={[]}>
            {BillingModal}
            <div className="ll-period-config-container">
                <Row className="menuRow">
                    <h3>Configure Exemption period</h3>
                </Row>
                <div>
                    {(changingTitle && (
                        <Search
                            placeholder={
                                periodTitle || period?.period_title || 'Input period title'
                            }
                            className="ll-period-config-title"
                            allowClear
                            loading={changingTitleLoading}
                            enterButton={<EditOutlined />}
                            onSearch={updateTitle}
                        />
                    )) || (
                        <div className="ll-period-config-title-edit">
                            <h3 style={{ textAlign: 'center', width: '100%' }}>
                                {periodTitle || period?.period_title || 'No Title'}
                            </h3>{' '}
                            <Button
                                onClick={() => {
                                    setChangingTitle(true)
                                }}
                            >
                                <EditOutlined />
                            </Button>
                        </div>
                    )}
                </div>
                <Typography>Press the edit buttons and pick the date on the calendar:</Typography>
                <div className="ll-period-config-container">
                    <div className="ll-period-config-boundary-setter">
                        <StartButton editPeriodBound={editPeriodBound} />
                        <div>
                            {(period &&
                                period.start &&
                                formatText(toDate(period.start), 'dd. MMM yyyy')) ||
                                'Open Start'}
                        </div>
                    </div>
                    <EarliestEndInfo startDate={period?.start ? toDate(period?.start) : null} />
                    <div className="ll-period-config-boundary-setter">
                        <EndButton editPeriodBound={editPeriodBound} />
                        <div>
                            {(period &&
                                period.end &&
                                formatText(toDate(period.end), 'dd. MMM yyyy')) ||
                                'Open End'}
                        </div>
                    </div>
                </div>
                <div>
                    <h4>Pick color</h4>
                    {colorSchema.map((c: string, idx) => {
                        return (
                            <label key={c} className={`radio-label-${c}`}>
                                <input
                                    onClick={changeColor}
                                    type="radio"
                                    name="colors"
                                    value={c}
                                    checked={c === periodColor}
                                    onChange={() => {}}
                                />
                                <span></span>
                            </label>
                        )
                    })}
                </div>
                <div className="menuRow">
                    <Popconfirm
                        title="Are you sure to delete this period?"
                        onConfirm={deleteEXPeriod}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button loading={deleteLoading} danger>
                            Delete exemption period
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        </Modal>
    )
}

function CreateExemptionModal({
    createEXPeriod,
    setCreateEXPeriod,
    updateEXPeriods,
}: IExemptionModal) {
    const [createLoading, setCreateLoading] = useState<boolean>(false)
    const [periodTitle, setPeriodTitle] = useState<string | null>(null)
    const editPeriodBound = (inputEditType: editType) => {
        setCreateEXPeriod({
            periodCreate: createEXPeriod.periodCreate,
            changeType: inputEditType,
            visible: false,
        })
    }
    const { session } = useSessionIfExists()
    const { openDialog: openBilllingModal, DialogComponent: BillingModal } =
        createBillingModal(session)

    const cancelModal = () => {
        setCreateEXPeriod({
            periodCreate: createEXPeriod.periodCreate,
            changeType: createEXPeriod.changeType,
            visible: false,
        })
    }

    const completeModal = async () => {
        setCreateLoading(true)
        let startInput = null
        let endInput = null
        if (createEXPeriod.periodCreate?.start) {
            startInput = formatText(toDate(createEXPeriod.periodCreate.start), layoutDictKeyFormat)
        }
        if (createEXPeriod.periodCreate?.end) {
            endInput = formatText(toDate(createEXPeriod.periodCreate.end), layoutDictKeyFormat)
        }
        try {
            await httpPost<ILLChange>('/add-ll-period', {
                start_date: startInput,
                end_date: endInput,
                period_title: periodTitle,
            })
            await updateEXPeriods('graph day-release ll-period')
            message.success('Created Exemption period!')
            setCreateEXPeriod({
                periodCreate: { start: null, end: null },
                changeType: null,
                visible: false,
            })
        } catch (error) {
            if (error.status && error.status === 402) {
                openBilllingModal()
                setCreateLoading(false)
                return
            }
            setCreateLoading(false)
            Modal.error({
                title: 'Could not create exemption period',
                content: error.data.detail,
            })
            return
        }
        setCreateLoading(false)
    }

    return (
        <Modal
            visible={createEXPeriod.visible}
            onCancel={cancelModal}
            onOk={cancelModal}
            footer={[]}
            destroyOnClose
        >
            {BillingModal}
            <div className="ll-period-config-container">
                <Row className="menuRow">
                    <h3>Add a new Exemption period</h3>
                </Row>
                <div>
                    <Input
                        placeholder="Period Title"
                        allowClear
                        onChange={(v) => {
                            setPeriodTitle(v.target.value)
                        }}
                    />
                </div>
                <Typography>Press the edit buttons and pick the date on the calendar:</Typography>
                <div className="ll-period-config-container">
                    <div className="ll-period-config-boundary-setter">
                        <StartButton editPeriodBound={editPeriodBound} />
                        <div>
                            {(createEXPeriod.periodCreate &&
                                createEXPeriod.periodCreate.start &&
                                formatText(
                                    toDate(createEXPeriod.periodCreate.start),
                                    'dd. MMM yyyy'
                                )) ||
                                'Open Start'}
                        </div>
                    </div>
                    <EarliestEndInfo
                        startDate={
                            createEXPeriod.periodCreate?.start
                                ? toDate(createEXPeriod.periodCreate?.start)
                                : null
                        }
                    />
                    <div className="ll-period-config-boundary-setter">
                        <EndButton editPeriodBound={editPeriodBound} />
                        <div>
                            {(createEXPeriod.periodCreate &&
                                createEXPeriod.periodCreate.end &&
                                formatText(
                                    toDate(createEXPeriod.periodCreate.end),
                                    'dd. MMM yyyy'
                                )) ||
                                'Open End'}
                        </div>
                    </div>
                    <Button loading={createLoading} type="primary" onClick={completeModal}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
