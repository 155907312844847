import { formatText } from '../../lib/dateFunctions'
import { useGetPeriodsQuery } from '../../redux/services/llPeriods'
import * as React from 'react'
import { toDate } from 'date-fns'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export default function () {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const { data } = useGetPeriodsQuery('')

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    if (!data?.eligible || !data?.earliest_end_date) {
        return <></>
    }
    return (
        <div className="switches-container">
            <Typography
                sx={{ fontSize: '21px', fonntWeight: 'bold', textAlign: 'center', my: 'auto' }}
            >
                {formatText(toDate(new Date(data.earliest_end_date)), 'yyyy MMM d.')}
            </Typography>
            <Typography
                sx={{ fontSize: '14px', marginLeft: '10px', textAlign: 'center', my: 'auto' }}
            >
                Earlist period end
            </Typography>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <HelpOutlineIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>
                    The exemption period can end on a date which;
                    <br />
                    you arrive in Denmark at least 6 months after period start.
                </Typography>
            </Popover>
        </div>
    )
}
