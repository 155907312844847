import { Button, Checkbox, Input, Popconfirm } from 'antd'
import { isSameDay } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { formatText, layoutDictKeyFormat, userNotesReceiver } from '../../lib/dateFunctions'
import { IGraphPoint } from '../../lib/interfaces'
import {
    colorSchema,
    colorConverterInv,
    colorConverter,
    colorConverterCSS,
} from '../../lib/userNoteColors'
import { httpDelete, httpPut } from '../../services/http'
import './style.less'

const { TextArea } = Input

export function UserNoteRow({
    userNote,
    setUserNotes,
    userNotes,
    updateData,
    changeContent,
    setChangeContent,
    menuModalVisible,
}: {
    userNote: userNotesReceiver
    setUserNotes: any
    userNotes: userNotesReceiver[]
    updateData: (partial?: string, targetDate?, alterFun?: (dp: IGraphPoint) => IGraphPoint) => void
    changeContent: boolean
    setChangeContent: (to: boolean) => void
    menuModalVisible: boolean
}) {
    const [includeReport, setIncludeReport] = useState<boolean>(userNote.include_report)
    const [includeGraph, setIncludeGraph] = useState<boolean>(userNote.include_graph)
    const [content, setContent] = useState<string>(userNote.note_content)
    const [color, setColor] = useState<string>(userNote.note_color)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    useEffect(() => {
        setIncludeReport(userNote.include_report)
        setIncludeGraph(userNote.include_graph)
        setChangeContent(false)
        setContent(userNote.note_content)
        setColor(userNote.note_color)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNote])

    useEffect(() => {
        if (!content) return
        if (content === userNote.note_content) return
        saveText({ target: { value: content } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuModalVisible])

    const onChangeRep = () => {
        setIncludeReport(!includeReport)
        httpPut(`/user-notes/${formatText(userNote.target_date, layoutDictKeyFormat)}`, {
            include_report: !includeReport,
        })
    }

    const onChangeGraph = () => {
        const newIncludeGraphValue = !includeGraph
        setIncludeGraph(newIncludeGraphValue)
        httpPut(`/user-notes/${formatText(userNote.target_date, layoutDictKeyFormat)}`, {
            include_graph: newIncludeGraphValue,
        })
        // updateData()
        const thisNote: userNotesReceiver = {
            ...userNote,
            include_graph: newIncludeGraphValue,
            include_report: includeReport,
            note_color: color,
            note_content: content,
        }
        setUserNotes(
            userNotes.map((n) => {
                if (isSameDay(n.target_date, userNote.target_date)) {
                    return thisNote
                } else return n
            })
        )
        updateData('graph', userNote.target_date, (dp) => {
            let newDp = dp
            if (newIncludeGraphValue) {
                switch (userNote.note_color) {
                    case 'usernotePurple':
                        newDp['user_note_purple'] = 1
                        newDp['user_note_red'] = null
                        newDp['user_note_yellow'] = null
                        break
                    case 'usernoteRed':
                        newDp['user_note_purple'] = null
                        newDp['user_note_red'] = 1
                        newDp['user_note_yellow'] = null
                        break
                    case 'usernoteYellow':
                        newDp['user_note_purple'] = null
                        newDp['user_note_red'] = null
                        newDp['user_note_yellow'] = 1
                        break
                }
            } else {
                newDp['user_note_purple'] = null
                newDp['user_note_red'] = null
                newDp['user_note_yellow'] = null
            }
            return newDp
        })
    }

    const changeColor = (c) => {
        setColor(colorConverter[c.target.value])
        httpPut(`/user-notes/${formatText(userNote.target_date, layoutDictKeyFormat)}`, {
            color: c.target.value,
        })
        // userNote.note_color = colorConverter[c.target.value]
        // updateData()
        const thisNote: userNotesReceiver = {
            ...userNote,
            include_graph: includeGraph,
            include_report: includeReport,
            note_color: colorConverter[c.target.value],
            note_content: content,
        }
        setUserNotes(
            userNotes.map((n) => {
                if (isSameDay(n.target_date, userNote.target_date)) {
                    return thisNote
                } else return n
            })
        )
        updateData('graph', userNote.target_date, (dp) => {
            let newDp = dp
            switch (c.target.value) {
                case 'purple':
                    newDp['user_note_purple'] = 1
                    newDp['user_note_red'] = null
                    newDp['user_note_yellow'] = null
                    break
                case 'red':
                    newDp['user_note_purple'] = null
                    newDp['user_note_red'] = 1
                    newDp['user_note_yellow'] = null
                    break
                case 'yellow':
                    newDp['user_note_purple'] = null
                    newDp['user_note_red'] = null
                    newDp['user_note_yellow'] = 1
                    break
            }
            return newDp
        })
    }

    const onTextClick = () => {
        setChangeContent(!changeContent)
    }

    const saveText = async (v) => {
        setChangeContent(false)
        await httpPut(`/user-notes/${formatText(userNote.target_date, layoutDictKeyFormat)}`, {
            content: v.target.value,
        })
        const thisNote: userNotesReceiver = {
            ...userNote,
            include_graph: includeGraph,
            include_report: includeReport,
            note_color: color,
            note_content: content,
        }
        setUserNotes(
            userNotes.map((n) => {
                if (isSameDay(n.target_date, userNote.target_date)) {
                    return thisNote
                } else return n
            })
        )
        setContent(v.target.value)
    }

    const deleteNote = async () => {
        setDeleteLoading(true)
        await httpDelete(`/user-notes/${formatText(userNote.target_date, layoutDictKeyFormat)}`)
        setUserNotes(userNotes.filter((n) => !isSameDay(n.target_date, userNote.target_date)))
        await updateData('graph', userNote.target_date, (dp) => {
            return {
                ...dp,
                user_note_red: null,
                user_note_yellow: null,
                user_note_purple: null,
            }
        })
        setDeleteLoading(false)
    }

    return (
        <div className="user-note-row">
            {(!changeContent && (
                <h4 style={{fontSize: '16px'}} onClick={onTextClick}>
                    {(content && content.length > 0 && content) || 'Click to change'}
                </h4>
            )) || (
                <div>
                    <TextArea
                        autoFocus
                        style={{fontSize: '16px'}}
                        placeholder="Click to change"
                        value={content}
                        onChange={(v) => {
                            setContent(v.target.value)
                        }}
                        onPressEnter={saveText}
                        maxLength={200}
                    />
                    <Button onClick={() => saveText({ target: { value: content } })} size="small">
                        save
                    </Button>
                </div>
            )}
            <div>
                <div className="user-note-row-config-container">
                    <div>
                        <Checkbox checked={includeReport} onChange={onChangeRep}>
                            On Report
                        </Checkbox>
                    </div>
                    <div>
                        <Checkbox checked={includeGraph} onChange={onChangeGraph}>
                            On Graph
                        </Checkbox>
                    </div>
                </div>
                <div style={{ paddingTop: '10px' }}>
                    Color{' '}
                    {colorSchema.map((c: string, idx) => {
                        return (
                            <label
                                key={colorConverterInv[c]}
                                className={`radio-label-${colorConverterCSS[c]}`}
                            >
                                <input
                                    onChange={changeColor}
                                    // onClick={changeColor}
                                    type="radio"
                                    name="colors"
                                    value={colorConverterInv[c]}
                                    checked={c === color}
                                />
                                <span></span>
                            </label>
                        )
                    })}
                </div>
                <div style={{ paddingTop: '10px', marginBottom: '5px' }}>
                    <Popconfirm
                        title="Are you sure?"
                        okText="yes"
                        cancelText="no"
                        onConfirm={deleteNote}
                    >
                        <Button danger size="small" loading={deleteLoading}>
                            Delete Note
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        </div>
    )
}
